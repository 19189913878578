@import "modules/variables.scss";
body {
    background: $color-white;
}

.re-reset-password-container {
    max-width: 540px;
    margin: $spacing-xl auto;
    text-align: center;
    padding: $spacing-sm;

    p {
        margin: 1em 0;
    }
}

.re-reset-password-header {
    font-weight: $font-weight-semibold;

    &:before{
        padding-right: 5px;
        font-size: 24px;
    }
}

.re-reset-password-desc {
    margin: $spacing-xl auto;

    .re-message {
        left: auto;
        margin: $spacing-xl 0;
    }
}

.re-reset-password-step {
    font-weight: $font-weight-semibold;
}

.re-reset-password-form {
    margin: $spacing-lg auto 0;
    text-align: left;
}

.re-reset-password-btn {
    text-align: right;
}

.re-reset-password-hr {
    margin: $spacing-xl 0;
    border-color: $color-grey-light;
}